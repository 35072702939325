
import React from 'react'

import './referralPolicy.scss'

const ReferralPolicy = () => {
    return (
        <>
	    <div className='main-content'>
		    <div className='title'> REFERRAL PROGRAM TERMS AND CONDITIONS </div>
            <div id="tos_content">
                <p>
                    <br/>These terms ("<strong>Terms</strong>") apply to a User's participation
                    in the Referral Program(“ <strong>Program”</strong>). By participating in
                    the Program, Users agree to use the Program as outlined herein, and
                    consistent with any other terms we may apply to the Program. If you do not
                    agree to these Terms in their entirety, then you cannot register and
                    participate in the Program. Users also cannot where in so doing, they would
                    violate any applicable law or regulations. We reserve the right to
                    disqualify any User at any time from participation in the Program if he/she
                    does not comply with any of these Terms. Our failure to enforce any term of
                    these Terms shall not constitute a waiver of that provision. Any capitalised
                    terms used in these Terms, however not defined herein, shall have the same
                    meanings as attributed to such terms in the Primary Terms of Services on the
                    Platform. The Users shall continue to be bound to comply with the Primary
                    Terms of Services and these Terms shall be in addition to and not in
                    substitution /derogation to the Primary Terms of Services governing the
                    usage of Solv services. Solv reserves the right to modify at any time, all
                    or any of the terms applicable to the Program, including the terms contained
                    herein, without assigning any reasons or without any prior intimation
                    whatsoever. We also reserve the right to extend or discontinue or terminate
                    the Program without assigning any reasons or without any prior intimation
                    whatsoever at any time. 
                </p>
                <ol
                    style={{"listStyleType": "lower-alpha","paddingInlineStart": "32px","listStylePosition": "outside"}}>
                    <li style={{"marginTop":"10px"}}>Any User who successfully refers any other
                        business entity to onboard as a User onto the Platform will be defined
                        as the <styong>“Referrer”</styong> and any business entity who onboards
                        as a User onto the Platform through such Referrer shall be defined as
                        <strong>“Referee”</strong>.
                    </li>
                    <li style={{"marginTop":"10px"}}>For receiving the benefits under the Referral
                        Program, the Referee shall be required to click on the applicable URL or
                        mention the relevant referral code received from us during the User
                        onboarding process or journey. </li>
                    <li style={{"marginTop":"10px"}}>If the Referee onboards as a User using a
                        referral code or through the relevant URL we provide, then upon
                        successful completion of verification and onboarding, such Referee shall
                        be issued a discount coupon for usage on its first transaction or second
                        transaction, as determined by us from time to time, towards purchasing
                        any products on the Platform within the serviceable locations.</li>
                    <li style={{"marginTop":"10px"}}>Upon any Referee successfully completing its
                        first transaction on the Platform, the Referrer shall be issued a
                        discount coupon for usage on its next transaction towards purchasing any
                        products on the Platform within the serviceable locations.</li>
                    <li style={{"marginTop":"10px"}}>The details of discount coupons available to the
                        Referrer and Referee are set out in the Annexure hereto, which we may
                        change from time to time without prior intimation.</li>
                    <li style={{"marginTop":"10px"}}>If the User places a purchase order on the
                        Platform, however the relevant seller does not accept such purchase
                        order or the order is not processed, the User’s discount voucher shall
                        not be considered as consumed. Further, if the User places an order for
                        certain quantity of products on the Platform exceeding the threshold
                        amount of invoice values mentioned above, however the order is only
                        partially fulfilled by the seller as a result of which the invoice value
                        falls below the threshold amounts, any redemption of discount voucher by
                        the User shall be at the discretion and in the manner determined by us.
                    </li>
                    <li style={{"marginTop":"10px"}}>Any discount voucher once applied by Users for
                        placing orders on the Platform (including in case of cancelled/RTO
                        orders) shall be considered as consumed, unless otherwise stated in
                        these Terms specifically.</li>
                    <li style={{"marginTop":"10px"}}>The Users shall not be entitled to assign the
                        rights and benefits arising under this Program to any other person. No
                        requests for transfer or assignment of the benefits under the Program
                        shall be entertained. </li>
                    <li style={{"marginTop":"10px"}}>Under no circumstances will the benefit being
                        offered under this Program be provided in cash by us, i.e. there are no
                        cash or other alternatives available in whole or in part, in relation to
                        the benefits under this Program.</li>
                    <li style={{"marginTop":"10px"}}>We reserve the right to disqualify any merchant
                        establishment or User from the benefits of the Program and initiate
                        appropriate legal action, if any fraudulent activity is identified as
                        being carried out for the purpose of availing the benefits under the
                        Program. Further, we reserve the right to deny honouring the Program or
                        any benefit thereof on the grounds of suspicion or abuse of the Program
                        by any User without providing the User any explanation thereof.</li>
                </ol>


                <h3 className="text-center sub-heading" style={{"marginTop": "5vw"}}>ANNEXURE</h3>
                <div className="text-center sub-heading" >DETAILS OF COUPONS AVAILABLE
                    UNDER REFERRAL PROGRAM</div>
                <div>Validity of coupons: 15 days from the date of coupon issuance</div>
                <div>Program Expiry Date: 30 September 2022 </div>
                <div className="table-responsive des-tos">
                    <table className="table table-bordered" style={{"width": "100%", "margin": "0px auto"}}>
                        <tbody> 
                            <tr>
                                <th style={{"width":"10%"}}>Sl. No.</th>
                                <th>Offer Details</th>
                                <th>Eligible User (Referrer/Referee)</th>
                                <th>Applicable Category</th>
                                <th>Discount Offered (in Rs.)</th>
                                <th>Coupon Code</th>
                            </tr>
                            <tr>
                                <td style={{"textAlign":"center"}}>1</td>
                                <td>
                                    <ul style={{"listStyleType":"none"}}>
                                        <li><strong>Purchase Slab:</strong> Rs. 1,000 and above
                                        </li>
                                        <li><strong>Validity:</strong> 15 days from the date of
                                            coupon issuance</li>
                                        <li><strong>Coupon usage per buyer:</strong> Once</li>
                                    </ul>
                                </td>
                                <td>
                                    Referee
                                </td>
                                <td>
                                    FMCG & HoReCa
                                </td>
                                <td style={{"textAlign":"center"}}>
                                    125
                                </td>
                                <td style={{"textAlign":"center"}}>
                                    SOLV125
                                </td>
                            </tr>

                            <tr>
                                <td style={{"textAlign":"center"}}>2</td>
                                <td>
                                    <ul style={{"listStyleType":"none"}}>
                                        <li><strong>Purchase Slab:</strong> Rs. 3,000 and above
                                        </li>
                                        <li><strong>Validity:</strong> 15 days from the date of
                                            onboarding/coupon issuance</li>
                                        <li><strong>Coupon usage per buyer:</strong> Once</li>
                                    </ul>
                                </td>
                                <td>
                                    Referee
                                </td>
                                <td>
                                Apparel and Fashion, Consumer Electronics, Home Furnishing and Footwear
                                </td>
                                <td style={{"textAlign":"center"}}>
                                    300
                                </td>
                                <td style={{"textAlign":"center"}}>
                                    SOLV300
                                </td>
                            </tr>

                            <tr>
                                <td style={{"textAlign":"center"}}>3</td>
                                <td>
                                    <ul style={{"listStyleType":"none"}}>
                                        <li><strong>Purchase Slab:</strong> Rs. 3,000 and above
                                        </li>
                                        <li><strong>Validity:</strong> 15 days from the date of
                                            coupon issuance</li>
                                        <li><strong>Coupon usage per buyer:</strong> Once</li>
                                    </ul>
                                </td>
                                <td>
                                    Referrer
                                </td>
                                <td>
                                    Across all categories
                                </td>
                                <td style={{"textAlign":"center"}}>
                                    300
                                </td>
                                <td style={{"textAlign":"center"}}>
                                    REFER300
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
		</div>
	</>
    )
}

export default ReferralPolicy
